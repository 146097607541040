import React, { useState } from 'react';
import Header from './header';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Checkout from './checkout';

const Pricing = ({ loggedIn, initialOptions, setPaid, signOutWithGitHub, signInWithGitHub }) => {
    const [selectedPlan, setSelectedPlan] = useState(null);

    const instanceTypes = [
        { name: "t4g.nano", price: 7, vCPU: 2, memory: "512 MB", storage: "EBS Only", network: "Up to 5 Gigabit" },
        { name: "t4g.micro", price: 16, vCPU: 2, memory: "1 GB", storage: "EBS Only", network: "Up to 5 Gigabit" },
        { name: "t4g.small", price: 32, vCPU: 2, memory: "2 GB", storage: "EBS Only", network: "Up to 5 Gigabit" },
        { name: "t4g.medium", price: 48, vCPU: 2, memory: "4 GB", storage: "EBS Only", network: "Up to 5 Gigabit" },
        { name: "t4g.large", price: 96, vCPU: 2, memory: "8 GB", storage: "EBS Only", network: "Up to 5 Gigabit" },
        { name: "t4g.xlarge", price: 192, vCPU: 4, memory: "16 GB", storage: "EBS Only", network: "Up to 5 Gigabit" },
        { name: "t4g.2xlarge", price: 390, vCPU: 8, memory: "32 GB", storage: "EBS Only", network: "Up to 5 Gigabit" }
    ];

    const PricingTier = ({ instance, isSelected, onSelect }) => (
        <div
            onClick={() => onSelect(instance)}
            className={`border m-4 rounded-lg cursor-pointer grid grid-cols-2 p-4 ${isSelected ? 'border-purple-400' : 'border-gray-300 hover:border-purple-400'}`}
        >
            <div>
                <h2 className="text-lg font-medium text-gray-800">{instance.name}</h2>
                <p className="text-gray-600 font-medium text-sm">${instance.price} USD / month</p>
            </div>
            <div className="mt-2 text-sm">
                <p className="text-gray-700 text-end">{instance.memory} (RAM)</p>
                <p className="text-gray-700 text-end">{instance.vCPU} vCPU</p>
                <p className="text-gray-700 text-end">{instance.network}</p>
            </div>
        </div>
    );

    return (
        <>
            <Header loggedIn={loggedIn} signOutWithGitHub={signOutWithGitHub} signInWithGitHub={signInWithGitHub} />
            <div className='sm:px-16 px-4 pt-32 pb-4'>
                <p className='text-lg font-medium text-gray-800'>Instance Types and pricing</p>
            </div>
            <div className="bg-white mx-auto sm:px-16 p-4">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {instanceTypes.map((instance, index) => (
                        <PricingTier
                            key={index}
                            instance={instance}
                            isSelected={selectedPlan && selectedPlan.name === instance.name}
                            onSelect={setSelectedPlan}
                        />
                    ))}
                </div>
            </div>
            <div className='flex pb-44 justify-center'>
                <PayPalScriptProvider className="sm:w-full" options={initialOptions}>
                    <Checkout className="2xl:w-full" setPaid={setPaid} selectedPlan={selectedPlan} />
                </PayPalScriptProvider>
            </div>
        </>
    );
};

export default Pricing;