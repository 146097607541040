import React, {useState} from "react"
import {Link} from "react-router-dom"
import {FaGithub} from "react-icons/fa"

const Header = ({ loggedIn, signOutWithGitHub, signInWithGitHub }) => {
    return(
        <>
            <header className="bg-white shadow-sm fixed w-full z-50">
                <div className="container mx-auto flex justify-between items-center py-4 px-6">
                    <div className="flex items-center">
                        <Link to="/" className="text-2xl cursor-pointer font-bold text-indigo-600">CoolCloud</Link>
                    </div>
                    <nav className="hidden md:flex space-x-6">
                        <Link to="/pricing" className="text-gray-600 hover:text-indigo-600 transition-colors">
                            Pricing
                        </Link>
                        <Link to="/integration" className="text-gray-600 flex items-center hover:text-indigo-600 transition-colors">
                            Integration with AWS
                            <span className="ml-2 bg-yellow-400 text-xs px-1 rounded">Soon</span>
                        </Link>
                    </nav>
                    <div className="flex items-center space-x-4">

                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;