import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Loader2 } from 'lucide-react';
import Integration from "./components/integration";
import Tutorial from "./components/tutorial";
import { query, where, doc, getDoc } from 'firebase/firestore';
import { signOut } from "firebase/auth";
import { initializeApp } from "firebase/app";
import Pricing from "./components/prices";
import { getAuth, signInWithPopup, GithubAuthProvider, onAuthStateChanged, setPersistence, browserLocalPersistence } from "firebase/auth";
import Home from "./components/home";
import Dashboard from "./components/dashboard";
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBPxueucxQtV0Zbxe8BtlT6GoSR753TNd4",
  authDomain: "gammafy-638dc.firebaseapp.com",
  projectId: "gammafy-638dc",
  storageBucket: "gammafy-638dc.appspot.com",
  messagingSenderId: "849864306670",
  appId: "1:849864306670:web:506911cfb604f41628a8ff",
  measurementId: "G-74ZH9LRDP3"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const provider = new GithubAuthProvider();
provider.addScope('repo');


const initialOptions = {
  "client-id": "AWa_g4N5yfNT9FgV6Kdhr8AX905OZBRMkYs4YK7uqNJlIOP9cmrCAu1nlUDdUmCDgSFpg4ME3gUDLGTJ",
  currency: "USD",
  intent: "capture",
};


function App() {
  const precios = [7, 16, 32, 48, 96, 192, 390]
  const [paid, setPaid] = useState(false)
  const [repositories, setRepositories] = useState([])
  const [loggedIn, setLoggedIn] = useState(false)
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [user, setUser] = useState(null);

  const [loading, setLoading] = useState(true);

  const [userPlan, setUserPlan] = useState(null);
  const [deploymentCount, setDeploymentCount] = useState(0);
  const [canDeploy, setCanDeploy] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        const userRef = doc(db, "paying_users", user.uid);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists() && docSnap.data().paid) {
          setPaid(true);
          const plan = docSnap.data().plan || "Free Tier";
          setUserPlan(plan);
          const deploymentsRef = collection(db, "deployments");
          const q = query(deploymentsRef, where("uid", "==", user.uid));
          const querySnapshot = await getDocs(q);
          const deployments = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          const count = deployments.length;
          setDeploymentCount(count);
          setCanDeploy(determineDeploymentPermission(plan, count));
        } else {
          setPaid(false);
          setUserPlan("Free Tier");
          setDeploymentCount(0);
          setCanDeploy(true);
        }
      } else {
        setUser(null);
        setPaid(false);
        setUserPlan(null);
        setDeploymentCount(0);
        setCanDeploy(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const determineDeploymentPermission = (plan, count) => {
    switch (plan) {
      case "Free Tier":
        return count < 2;
      case "Basic":
        return count < 3;
      case "Pro":
        return count < 5;
      case "Enterprise":
        return true;
      default:
        return false;
    }
  };

  const signInWithGitHub = () => {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        return signInWithPopup(auth, provider);
      })
      .then((result) => {
        const credential = GithubAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const signedInUser = result.user;
        setUser(signedInUser);
        console.log("User signed in:", signedInUser);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error during sign-in:", errorCode, errorMessage);
      });
  };

  const signOutWithGitHub = () => {
    const auth = getAuth();
    return signOut(auth)
      .then(() => {
        console.log("User signed out successfully");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  }; 

  const [retrievedDeployments, setRetrievedDeployments] = useState([]);
  const [loadingDeployments, setLoadingDeployments] = useState(false);

  useEffect(() => {
    const fetchDeployments = async () => {
      if (user) {
        setLoadingDeployments(true);
        try {
          const deploymentsRef = collection(db, "deployments");
          const q = query(deploymentsRef, where("uid", "==", user.uid));
          const querySnapshot = await getDocs(q);
          const deployments = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setRetrievedDeployments(deployments);
          setDeploymentCount(deployments.length);
          setCanDeploy(determineDeploymentPermission(userPlan, deployments.length));
        } catch (error) {
          console.error("Error fetching deployments:", error);
        } finally {
          setLoadingDeployments(false);
        }
      }
    };
    fetchDeployments();
  }, [user, db, userPlan]);

  useEffect(() => {

    (function (c, l, a, r, i, t, y) {
      c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
      t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "ncjaf4b0vo");
  }, []);

  if (loading) {
   return <div className="flex py-20 justify-center items-center">
      <Loader2 className="w-6 h-6 text-gray-500 animate-spin" />
    </div>
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home signOutWithGitHub={signOutWithGitHub} auth={auth} provider={provider} setRepositories={setRepositories} signInWithGitHub={signInWithGitHub} setShowEmailForm={setShowEmailForm} showEmailForm={showEmailForm} setEmail={setEmail} email={email} setPassword={setPassword} password={password} showPopup={showPopup} setShowPopup={setShowPopup} user={user} loggedIn={loggedIn} />} />
        <Route path="/pricing" element={<Pricing initialOptions={initialOptions} setPaid={setPaid} precios={precios} paid={paid} loggedIn={loggedIn} signOutWithGitHub={signOutWithGitHub} signInWithGitHub={signInWithGitHub} />} />
        <Route path="/dashboard" element={<Dashboard 
          user={user}
          userPlan={userPlan}
          deploymentCount={deploymentCount}
          canDeploy={canDeploy}
          precios={precios}
          paid={paid}
          signOutWithGitHub={signOutWithGitHub}
          setPaid={setPaid}
          initialOptions={initialOptions} data={retrievedDeployments} repositories={repositories} />} />
        <Route path="/integration" element={<Integration/>} />
        <Route path="/tutorial" element={<Tutorial user={user} signOutWithGitHub={signOutWithGitHub} signInWithGitHub={signInWithGitHub} />} />
      </Routes>
    </Router>
  );
}

export default App;  
