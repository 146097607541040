import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowRight, Check } from 'lucide-react';
import { Link } from "react-router-dom";
import {
    Github,
    Globe,
    Server,
    Terminal,
    RefreshCw,
    Lock,
} from 'lucide-react';
import { Zap, Database, PenTool, Cloud, GitBranch, Shield, Users } from 'lucide-react';
import { FaCloud, FaBolt, FaDatabase, FaCheckCircle, FaPencilAlt, FaChevronRight, FaGithub, FaRocket, FaCode, FaLightbulb, FaPython, FaNodeJs, FaGem, FaReact, FaVuejs } from "react-icons/fa";

const Home = ({ signInWithGitHub, user, signOutWithGitHub, loggedIn }) => {
    const navigate = useNavigate();

    const features = [
        {
            icon: Zap,
            title: "Build with speed",
            description: "Link your repo and go live—then iterate with every push.",
            subFeatures: [
                "Automatic Deploys",
                "Native Language Runtimes",
                "Enterprise-Grade Data Stores",
                "Private Network"
            ]
        },
        {
            icon: Cloud,
            title: "Scale with confidence",
            description: "Grow your infrastructure, team, and business with a platform that scales alongside you.",
            subFeatures: [
                "Load-Based Autoscaling",
                "Infrastructure as Code",
                "DDoS Protection",
                "Data Privacy"
            ]
        },
        {
            icon: Users,
            title: "Ship together",
            description: "Collaborate with your team on everything from one-line fixes to full-scale migrations.",
            subFeatures: [
                "Project Management",
                "Preview Environments",
                "Platform Notifications",
                "Role-Based Access"
            ]
        }
    ];

    const services = [
        "Static Sites",
        "Web Services",
        "Background Workers",
        "Cron Jobs"
    ];

    const steps = [
        {
            icon: Globe,
            title: "Go to CoolCloud",
            description: "Visit coolcloud.app and sign up or log in to your account.",
        },
        {
            icon: Github,
            title: "Connect GitHub",
            description: "Link your GitHub account to access your repositories.",
        },
        {
            icon: Server,
            title: "Create Web Service",
            description: "Click 'Web Service' in your dashboard to start a new deployment.",
        },
        {
            icon: Terminal,
            title: "Configure Deployment",
            description: "Select your repository, set the name, and specify 'node index.js' as the start command.",
        },
        {
            icon: Check,
            title: "Verify Port",
            description: "Ensure your app listens on port 80.",
        },
        {
            icon: RefreshCw,
            title: "Deploy",
            description: "Click 'Deploy' and wait for the process to complete.",
        },
        {
            icon: Lock,
            title: "Access Your App",
            description: "Use the provided HTTPS endpoint to securely access your deployed application.",
        }
    ];

    const goToDashboard = () => {
        navigate("/dashboard");
    }

    return (
        <div className="bg-gray-50 text-gray-800 min-h-screen flex flex-col">
            <header className="bg-white shadow-sm fixed w-full z-50">
                <div className="container mx-auto flex justify-between items-center py-4 px-6">
                    <div className="flex items-center">
                        <span className="text-2xl font-bold text-indigo-600">CoolCloud</span>
                    </div>
                    <nav className="hidden md:flex space-x-6">
                        <Link to="/pricing" className="text-gray-600 hover:text-indigo-600 transition-colors">
                            Pricing
                        </Link>
                        <Link to="/integration" className="text-gray-600 flex items-center hover:text-indigo-600 transition-colors">
                            Integration with AWS
                            <span className="ml-2 bg-yellow-400 text-xs px-1 rounded">Soon</span>
                        </Link>
                        <Link to="/tutorial" className="text-gray-600 hover:text-indigo-600 transition-colors">
                            Docs
                        </Link>
                    </nav>
                    <div className="flex items-center space-x-4">
                        <a href="mailto:hector.diaz@pucp.edu.pe" className="text-gray-600 sm:flex hidden hover:text-indigo-600 transition-colors">Contact</a>
                        {user && (
                            <Link to="/dashboard" className="text-gray-600 hidden sm:flex hover:text-indigo-600 transition-colors">
                                Dashboard
                            </Link>
                        )}
{/*
                        <button
                            onClick={user? signOutWithGitHub : signInWithGitHub}
                            className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-full transition-colors flex items-center"
                        >
                            <FaGithub className="mr-2" />
                            {user ? "Sign out" : "Sign up"}
                        </button>
*/}
                    </div>
                </div>
            </header>
            <main className="flex-grow pt-16">
                <section className="bg-gradient-to-r from-blue-50 to-indigo-100 text-gray-800 min-h-screen flex items-center">
                    <div className="container mx-auto text-center px-4">
                        <h1 className="text-5xl md:text-7xl font-bold mb-6 leading-tight text-gray-800">
                            Your fastest path to production
                        </h1>
                        <p className="text-xl md:text-2xl mb-12 max-w-3xl mx-auto text-gray-600">
                            Build, deploy, and scale your apps with unparalleled ease – from your first user to your billionth.
                        </p>
                        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
            {/*
                                            <button onClick={user ? goToDashboard : signInWithGitHub} className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-4 px-8 rounded-full transition-colors text-lg">
                                    {user ? "Go to dashboard" : "Get Started for Free"}
                                </button>
            */}
                                <a href="https://youtu.be/9nO4jN6Q3c4?si=AhNsf77iEEHzKV0i" target="_blank" className="bg-white hover:bg-gray-100 text-indigo-600 font-bold py-4 px-8 rounded-full border-2 border-indigo-600 transition-colors text-lg">
                                    Tutorial
                                </a>
                        </div>
                        <p className="text-red-600 pt-5">Due to the high volume of users we are not accepting new users at this time.</p>
                    </div>
                </section>

                <section className="py-16 bg-white">
                    <div className="container mx-auto px-4 sm:px-32">
                        <h2 className="text-3xl font-bold mb-12 text-center">Whatever your stack, it runs on CoolCloud</h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                            {features.map((feature, index) => (
                                <div key={index} className="bg-gray-50 p-6 rounded-lg">
                                    <feature.icon className="w-12 h-12 text-indigo-600 mb-4" />
                                    <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
                                    <p className="text-gray-600 mb-4">{feature.description}</p>
                                    <ul className="space-y-2">
                                        {feature.subFeatures.map((subFeature, subIndex) => (
                                            <li key={subIndex} className="flex items-center">
                                                <Check className="w-5 h-5 text-green-500 mr-2" />
                                                <span>{subFeature}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <section className="py-16 bg-gray-50">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold mb-12 text-center">Services</h2>
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                            {services.map((service, index) => (
                                <div key={index} className="bg-white p-4 rounded-lg shadow text-center">
                                    {service}
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <div className="bg-gradient-to-b from-gray-50 to-white py-32 px-4 sm:px-6 lg:px-8">
                    <div className="max-w-4xl mx-auto">
                        <h2 className="text-4xl font-extrabold text-gray-900 text-center mb-12">
                            Sample: Deploy Your Node.js App on CoolCloud
                        </h2>
                        <div className="relative">
                            {steps.map((step, index) => (
                                <div key={index} className={`flex items-center mb-12 ${index % 2 === 0 ? '' : 'flex-row-reverse'}`}>
                                    <div className={`w-1/2 ${index % 2 === 0 ? 'pr-8 text-right' : 'pl-8 text-left'}`}>
                                        <h3 className="text-xl font-semibold text-indigo-600 mb-2">{step.title}</h3>
                                        <p className="text-gray-600">{step.description}</p>
                                    </div>
                                    <div className="w-12 h-12 flex-shrink-0 mx-4">
                                        <div className="w-12 h-12 border-4 border-indigo-200 bg-white rounded-full flex items-center justify-center">
                                            <step.icon className="h-6 w-6 text-indigo-500" />
                                        </div>
                                    </div>
                                    <div className="w-1/2"></div>
                                </div>
                            ))}
                            <div className="absolute h-full w-0.5 bg-indigo-200 left-1/2 top-0 transform -translate-x-1/2"></div>
                        </div>
                        <div className="mt-16 text-center">
{/*
                            <button
                                onClick={user ? goToDashboard : signInWithGitHub}
                                className="inline-flex items-center px-8 py-4 border border-transparent text-lg font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 transition-colors duration-300"
                            >
                                Start Your Deployment Journey
                                <ArrowRight className="ml-2 -mr-1 h-6 w-6" />
                            </button>
*/}
                        </div>
                    </div>
                </div>
            </main>
            <div className="flex py-3 text-white bg-gray-800 font-medium justify-center">
                CoolCloud 2024
            </div>
        </div>
    );
};

export default Home;