import React from 'react';
import { CloudIcon, CloudLightningIcon, CurrencyIcon } from 'lucide-react';
import Header from './header';

const Integration = () => {
    return (
<>
<Header/>
            <div className="bg-white px-6 pt-32 pb-6 rounded-lg shadow-lg max-w-3xl mx-auto">
                <h2 className="text-2xl font-medium text-indigo-600 mb-4">Coming Soon: AWS Integration</h2>
                <p className="text-gray-600 mb-6">
                    Deploy with the simplicity of CoolCloud and the cost-effectiveness of AWS.
                    Link your AWS account and enjoy the best of both worlds!
                </p>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
                    <FeatureCard
                        icon={<CloudIcon className="h-8 w-8 text-blue-500" />}
                        title="Easy Deployment"
                        description="Deploy your apps with just a few clicks, powered by CoolCloud's intuitive interface."
                    />
                    <FeatureCard
                        icon={<CloudLightningIcon className="h-8 w-8 text-yellow-500" />}
                        title="AWS Performance"
                        description="Leverage the power and reliability of AWS EC2 instances for your deployments."
                    />
                    <FeatureCard
                        icon={<CurrencyIcon className="h-8 w-8 text-green-500" />}
                        title="Cost-Effective"
                        description="Enjoy AWS's competitive pricing while using CoolCloud's streamlined workflow."
                    />
                </div>

                <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300">
                    Get Notified
                </button>
            </div>
</>
    );
};

const FeatureCard = ({ icon, title, description }) => {
    return (
        <div className="bg-gray-50 p-4 rounded-lg">
            <div className="flex items-center mb-2">
                {icon}
                <h3 className="text-lg font-semibold ml-2">{title}</h3>
            </div>
            <p className="text-sm text-gray-600">{description}</p>
        </div>
    );
};

export default Integration;