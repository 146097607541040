import React, { useState, useEffect } from 'react';
import { getAuth, signInWithPopup, GithubAuthProvider, setPersistence, browserLocalPersistence, updateProfile } from "firebase/auth";
import { Star, Flame, Cog, DollarSign, Users, ArrowLeft, Plus, Menu, User, CheckCheck, CircleX, CheckIcon, LoaderIcon, Globe2, ArrowRightIcon } from "lucide-react";
import { getFirestore, collection, getDocs, query, where, getDoc, setDoc, addDoc, serverTimestamp, onSnapshot, doc, updateDoc, arrayRemove } from 'firebase/firestore';
import { CiGlobe } from "react-icons/ci";
import { toast } from 'react-toastify';
import { FaServer, FaClock, FaPlus, FaTrash } from 'react-icons/fa';
import { FaGlobe, FaCog, FaGithub, FaCopy } from 'react-icons/fa';
import { Trash2, Pen } from 'lucide-react';
import { Globe, Server, Package } from 'lucide-react';
import { Link } from "react-router-dom";
import PricingTable from "./pricing"
import { initializeApp } from "firebase/app";
import axios from "axios";
import GithubConnect from './githubConnect';

const mockRequestPermission = (awsAccountId) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const cfnUrl = `https://console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/create/review?templateURL=https://luyaviejo.s3.amazonaws.com/modified-cloudformation-template.json&stackName=CCRole&param_ExternalIdParameter=${awsAccountId}&param_TrustedAccountId=435434614680`;
      resolve({ cfnUrl });
    }, 1000);
  });
};

const mockCheckPermission = (awsAccountId) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const permissionGranted = Math.random() < 0.2;
      resolve({ permissionGranted });
    }, 1000);
  });
};


const firebaseConfig = {
  apiKey: "AIzaSyBPxueucxQtV0Zbxe8BtlT6GoSR753TNd4",
  authDomain: "gammafy-638dc.firebaseapp.com",
  projectId: "gammafy-638dc",
  storageBucket: "gammafy-638dc.appspot.com",
  messagingSenderId: "849864306670",
  appId: "1:849864306670:web:506911cfb604f41628a8ff",
  measurementId: "G-74ZH9LRDP3"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const provider = new GithubAuthProvider();
provider.addScope('repo');

const Dashboard = ({ user, data, userPlan, deploymentCount, canDeploy, setPaid, initialOptions }) => {
  const [repositories, setRepositories] = useState([]);
  const [showList, setShowList] = useState(false);
  const [indRepo, setIndRepo] = useState(null);
  const [main, setMain] = useState(true);
  const [details, setDetails] = useState()
  const [forward, setForward] = useState(true);
  const [deploying, setDeploying] = useState(false);



  const [deployedUrl, setDeployedUrl] = useState('');

  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const [showTypes, setShowTypes] = useState(true)

  const signInWithGitHub = () => {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        return signInWithPopup(auth, provider);
      })
      .then((result) => {
        const credential = GithubAuthProvider.credentialFromResult(result);
        const newToken = credential.accessToken;
        setToken(newToken);
        setWebServiceFormData(prevData => ({
          ...prevData,
          token: newToken
        }));
        fetchRepositories(newToken);
        setShowList(true);
        setShowTypes(false)
      })
      .catch((error) => {
        console.error("Error during sign-in:", error.code, error.message);
      });
  };

  const fetchRepositories = async (accessToken, searchQuery = '', pageNum = 1) => {
    setLoading(true);
    try {
      const response = await fetch(`https://api.github.com/user/repos?page=${pageNum}&per_page=99&q=${searchQuery}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(response)
      if (pageNum === 1) {
        setRepositories(data);
      } else {
        setRepositories(prev => [...prev, ...data]);
      }

      setPage(pageNum);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching repositories:", error);
      setLoading(false);
    }
  };

  const [webServiceFormData, setWebServiceFormData] = useState({
    token: token,
    webAppName: '',
    repoUrl: '',
    sku: "t4g.nano",
    branch: 'main',
    runtime: 'node',
    startupCommand: '',
    location: 'us-east-1',
    nodejsVersion: '18.x',
    envVars: []
  });

  const goBack = () => {
    setShowList(true);
    setIndRepo(null);
    setMain(true);
    setForward(true);
  };

  const [nameAvailability, setNameAvailability] = useState(null);
  const appNamesRef = collection(db, "appnames");

  const checkNameAvailability = async (name) => {
    if (!name) {
      return 'Please enter a name first';
    }

    try {
      const q = query(appNamesRef, where('name', '==', name));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return 'Name available!';
      } else {
        return 'Name already taken!';
      }
    } catch (error) {
      console.error('Error checking name availability:', error);
      return 'Error checking availability';
    }
  };

  const handleCheckNameAvailability = async () => {
    const result = await checkNameAvailability(webServiceFormData.webAppName);
    setNameAvailability(result);
  };

  const [activeSection, setActiveSection] = useState('main');
  const handleReload = (e) => {
    e.preventDefault();
    window.location.reload();
    setActiveSection('main')
  };
  const menuItems = [
    { icon: Flame, text: "Deployments", onClick: (e) => handleReload(e) },
    { icon: Cog, text: "Integrations", onClick: () => setActiveSection('integrations') },
    { icon: DollarSign, text: "Billing", onClick: () => setActiveSection('billing') },
    { icon: Users, text: "Teams", onClick: () => setActiveSection('teams') },
    { icon: User, text: "Account", onClick: () => setActiveSection('user') }
  ];

  const stats = [
    { title: "Deployments", value: `${data?.length}` },
    { title: "Integrations", value: "0/1" },
    { title: "Cron jobs", value: "0" },
    { title: "Team members", value: "1" }
  ];

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const [selectedType, setSelectedType] = useState(null);
  const [cronJobs, setCronJobs] = useState([{ endpoint: '', schedule: '' }]);

  const deploymentTypes = [
    { id: 'webservice', name: 'Web Service', icon: FaServer },
    { id: 'cron', name: 'Cron Job', icon: FaClock },
  ];

  const [deploymentType, setDeploymentType] = useState('cron');

  const handleDeploymentTypeSelect = (type) => {
    if (!user) {
      signInWithGitHub();
      return;
    }

    if (!canDeploy) {
      const message = getPlanLimitMessage(userPlan, deploymentCount);
      toast.error(message);
      return;
    }

    signInWithGitHub();
    setSelectedType(type);
    console.log(`Deploying ${type}`);
  };

  const getPlanLimitMessage = (plan, count) => {
    switch (plan) {
      case "Free Tier" || null:
        return "You've reached the limit for the Free Tier. Upgrade to deploy more.";
      case "Basic":
        return `You've deployed ${count}/3 services. Upgrade to deploy more.`;
      case "Pro":
        return `You've deployed ${count}/5 services. Upgrade to deploy more.`;
      default:
        return "You've reached your plan's deployment limit. Please contact support.";
    }
  };

  const [cronFormData, setCronFormData] = useState({
    webAppName: '',
    repoUrl: '',
    branch: '',
    runtime: 'node',
    startupCommand: '',
    location: 'east-us-1',
    nodejsVersion: '18.x',
  });

  const handleWebServiceFormChange = (e) => {
    setWebServiceFormData({ ...webServiceFormData, [e.target.name]: e.target.value });
  };

  const deploymentsRef = collection(db, "deployments");
  const [getResponse, setGetResponse] = useState(false)
  const [successful, setSuccessful] = useState(false)
  
  const saveDeployment = async (websiteURL) => {
    try {
      const deploymentData = {
        uid: user.uid,
        endpoint: websiteURL,
        data: webServiceFormData,
        timestamp: serverTimestamp(),
      };

      const docRef = await addDoc(deploymentsRef, deploymentData);
      const webAppName = webServiceFormData.webAppName;
      await addDoc(appNamesRef, {
        name: webAppName,
      });
      return docRef.id;
    } catch (error) {
      console.error("Error saving deployment: ", error);
      throw error;
    }
  };

  const handleCronFormChange = (e) => {
    setCronFormData({ ...cronFormData, [e.target.name]: e.target.value });
  };

  const handleCronJobChange = (index, field, value) => {
    const updatedCronJobs = [...cronJobs];
    updatedCronJobs[index][field] = value;
    setCronJobs(updatedCronJobs);
  };

  const addCronJob = () => {
    setCronJobs([...cronJobs, { endpoint: '', schedule: '' }]);
  };

  const removeCronJob = (index) => {
    const updatedCronJobs = cronJobs.filter((_, i) => i !== index);
    setCronJobs(updatedCronJobs);
  };

  const [cronSubmit, setCronSubmit] = useState()

  const handleSubmitCron = async (e) => {
    e.preventDefault();
    setDeploying(true);
    console.log("Static Site Data sent: ", { ...cronFormData, cronJobs });
    try {
      const response = await axios.post('https://backendhectorcc.onrender.com/deploy-cron', {
        ...cronFormData,
        cronJobs,
      });
      setSuccessful(true);
      setCronSubmit(response.data);
    } catch (error) {
      setSuccessful(false);
    } finally {
      setDeploying(false);
    }
  };

  const handleSubmitWebService = async (e) => {
    e.preventDefault();
    setDeploying(true);
    setGetResponse(true);
    const envVarsString = JSON.stringify(webServiceFormData.envVars);
    try {
      const response = await axios.get('https://backendhectorcc.onrender.com/deploy-webapp', {
        params: {
          ...webServiceFormData,
          envVars: envVarsString
        }
      });
      setDetails(response.data);
      console.log(response.data);
      setGetResponse(false);
      setSuccessful(true);
      const websiteURL = response.data.outputs.find(output => output.OutputKey === 'WebsiteURL')?.OutputValue;
      if (websiteURL) {
        await saveDeployment(websiteURL);
      }
    } catch (error) {
      console.log(error);
      setGetResponse(false);
    } finally {
      setGetResponse(false);
      setDeploying(false);
    }
  };

  const handleSelect = (type) => {
    setSelectedType(type);
    setDeploymentType(type);
    handleDeploymentTypeSelect(type);
    console.log("Going ...")
  };

  const repoSelected = (repo) => {
    setShowList(false);
    setForward(false);
    setIndRepo(repo);
    const formData = {
      webAppName: repo.name,
      repoUrl: repo.html_url
    };
    setCronFormData(prev => ({ ...prev, ...formData }));
    setWebServiceFormData(prev => ({ ...prev, ...formData }));
  };

  const [selectedDeployment, setSelectedDeployment] = useState(null);

  const handleDeploymentClick = (deployment) => {
    setSelectedDeployment(deployment);
    setActiveSection('deploymentDetails');
  };

  const [editMode, setEditMode] = useState(false);
  const [updatedDeployment, setUpdatedDeployment] = useState({ ...selectedDeployment });
  const [newDomain, setNewDomain] = useState('');
  const [newEnvVar, setNewEnvVar] = useState({ key: '', value: '' });

  const renderDeploymentDetails = () => {
    if (!selectedDeployment) return null;

    const handleInputChange = (e) => {
      setUpdatedDeployment({
        ...updatedDeployment,
        data: { ...updatedDeployment.data, [e.target.name]: e.target.value }
      });
    };

    const handleSaveChanges = async () => {
      console.log('Saving changes:', updatedDeployment);
      setEditMode(false);
      setSelectedDeployment(updatedDeployment);
    };

    const handleAddDomain = () => {
      console.log('Adding new domain:', newDomain);
      setNewDomain('');
    };

    const handleAddEnvVar = () => {
      console.log('Adding new env var:', newEnvVar);
      setNewEnvVar({ key: '', value: '' });
    };


    
    return (
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 pt-5 pb-3 sm:px-6 flex justify-between items-center">
<div className='flex items-center'>
            <CiGlobe className='w-6 mr-2 h-auto text-gray-400' />
            Web service
</div>
          <button
            onClick={() => setEditMode(!editMode)}
            className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
          >
            {editMode ? 'Cancel' : 'Edit'}
          </button>
        </div>
<div className='px-6 pb-5'>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {selectedDeployment.data.webAppName}
          </h3>
</div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Repository</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <a href={selectedDeployment.data.repoUrl} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-500">
                  {selectedDeployment.data.repoUrl}
                </a>
              </dd>
              <dt className="text-sm flex items-center font-medium text-gray-500">Branch </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <a rel="noopener noreferrer" className="text-gray-600">
                  {selectedDeployment.data.branch}
                </a>
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Location</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {editMode ? (
                  <select
                    name="location"
                    value={updatedDeployment?.data?.location}
                    onChange={handleInputChange}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    <option value="eastus">East US</option>
                    <option value="westus">West US</option>
                    <option value="centralus">Central US</option>
                  </select>
                ) : (
                  selectedDeployment?.data?.location
                )}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Pricing Tier</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {editMode ? (
                  <select
                    name="sku"
                    value={updatedDeployment?.data?.sku}
                    onChange={handleInputChange}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    <option value="F1">Free Tier (F1)</option>
                    <option value="B1">Basic (B1)</option>
                    <option value="S1">Standard (S1)</option>
                  </select>
                ) : (
                  selectedDeployment?.data?.sku
                )}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Endpoint</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {selectedDeployment?.customDomains && selectedDeployment?.customDomains?.map((domain, index) => (
                  <div key={index} className="mb-2">{domain}</div>
                ))}
                {editMode && (
                  <div className="mt-2 flex">
                    <input
                      type="text"
                      value={newDomain}
                      onChange={(e) => setNewDomain(e.target.value)}
                      placeholder="Enter new domain"
                      className="flex-grow mr-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <button
                      onClick={handleAddDomain}
                      className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                    >
                      Add
                    </button>
                  </div>
                )}
              </dd>
            </div>
          </dl>
        </div>
        {editMode && (
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              onClick={handleSaveChanges}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save Changes
            </button>
          </div>
        )}
      </div>
    );
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleEnvVarChange = (index, field, value) => {
    setWebServiceFormData(prevData => {
      const newEnvVars = [...prevData.envVars];
      newEnvVars[index] = { ...newEnvVars[index], [field]: value };
      return { ...prevData, envVars: newEnvVars };
    });
  };

  const addEnvVar = () => {
    setWebServiceFormData(prevData => ({
      ...prevData,
      envVars: [...prevData.envVars, { key: '', value: '' }]
    }));
  };

  const removeEnvVar = (index) => {
    setWebServiceFormData(prevData => ({
      ...prevData,
      envVars: prevData.envVars.filter((_, i) => i !== index)
    }));
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100 md:flex-row">
      <header className="bg-white shadow-sm md:hidden">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <Link to="/" className="text-xl font-bold text-indigo-600 flex items-center">
            CoolCloud
          </Link>
          <button onClick={toggleMobileMenu} className="text-gray-500 hover:text-gray-600">
            <Menu size={24} />
          </button>
        </div>
      </header>
      <div className={`${mobileMenuOpen ? 'block' : 'hidden'} md:block md:w-64 bg-white shadow-lg`}>
        <div className="flex items-center justify-center h-16 border-b">
          <Link to="/" className="text-xl font-bold text-indigo-600 flex items-center">
            CoolCloud
          </Link>
        </div>
        <nav className="mt-6">
          {menuItems.map((item, index) => (
            <a
              key={index}
              onClick={item.onClick}
              className="flex items-center px-6 py-3 text-gray-600 hover:bg-indigo-50 hover:text-indigo-600 cursor-pointer"
            >
              <item.icon className="mr-3" size={20} />
              {item.text}
            </a>
          ))}
        </nav>
      </div>

      <div className="flex-1 overflow-y-auto">
        <header className="bg-white shadow-sm hidden md:block">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
            <h1 className="text-2xl font-semibold text-gray-900"></h1>
            <div className="bg-indigo-600 text-white w-10 h-10 flex items-center justify-center rounded-full font-bold">
              <img className='rounded-full' src={user?.photoURL} alt='' />
            </div>
          </div>
        </header>
        <main className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          {activeSection === 'main' && (
            <>
              <div className="py-6 flex items-center">
                <p className='text-xl font-semibold'>{user?.displayName}</p>
                <button className='px-3 py-1 font-semibold rounded-lg text-sm bg-gray-200 ml-2'>{userPlan}</button>
              </div>
              {forward && (
                <>
                  <div className="mb-8">
                    <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                      {stats.map((item, index) => (
                        <div key={index} className="bg-white overflow-hidden shadow rounded-lg">
                          <div className="px-4 py-5 sm:p-6">
                            <dt className="text-sm font-medium text-gray-500 truncate">{item.title}</dt>
                            <dd className="mt-1 text-2xl md:text-3xl font-semibold text-gray-900">{item.value}</dd>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {showTypes && (
                    <>
                      <div className="grid grid-cols-2 gap-4 mb-4">
                        {deploymentTypes.map((type) => (
                          <button
                            key={type.id}
                            onClick={() => handleSelect(type.id)}
                            className={`flex items-center justify-center p-4 border rounded-lg ${selectedType === type.id
                                ? 'bg-indigo-100 border-indigo-500'
                                : 'border-gray-300 hover:border-indigo-500'
                              } ${!canDeploy ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={!canDeploy}
                          >
                            <type.icon className="mr-2 text-indigo-600" size={20} />
                            <span>{type.name}</span>
                          </button>
                        ))}
                      </div>
                      <div className="mt-8">
                        {data?.length > 0 &&
                          <h2 className="text-2xl font-semibold text-gray-900 mb-6">My Deployments</h2>
                        }
                        <div className="grid grid-cols-1 gap-6">
                          {data.map((element, index) => (
                            <div key={index} className="bg-white overflow-hidden shadow rounded-lg">
                              <div className="px-4 py-5 sm:p-6">
                                <div onClick={() => handleDeploymentClick(element)} className='flex items-center cursor-pointer'>
                                  <h3 className="text-lg font-medium underline text-gray-900 truncate mb-2">
                                    {element?.data?.webAppName}
                                  </h3>
                <ArrowRightIcon className='text-gray-900 w-6 h-auto pl-2' />
                              </div>
                                <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                                  <div className="sm:col-span-1 flex items-center">
                                    <dt className="text-sm font-medium text-gray-500 flex items-center mb-1">
                                      <Globe className="mr-2 h-5 w-5 text-indigo-500" />
                                      Location
                                    </dt>
                                    <dd className="text-sm ml-2 text-gray-900 bg-indigo-50 rounded-md px-2 py-1 inline-block">
                                      {element?.data?.location}
                                    </dd>
                                  </div>
                                  <div className="sm:col-span-1 flex items-center">
                                    <dt className="text-sm font-medium text-gray-500 flex items-center mb-1">
                                      <Server className="mr-2 h-5 w-5 text-indigo-500" />
                                      Runtime
                                    </dt>
                                    <dd className="text-sm ml-2 text-gray-900 bg-indigo-50 rounded-md px-2 py-1 inline-block">
                                      {element?.data.runtime}
                                    </dd>
                                  </div>
                                  <div className="sm:col-span-1 flex items-center">
                                    <dt className="text-sm font-medium text-gray-500 flex items-center mb-1">
                                      <Package className="mr-2 h-5 w-5 text-indigo-500" />
                                      Tier
                                    </dt>
                                    <dd className="text-sm ml-2 text-gray-900 bg-indigo-50 rounded-md px-2 py-1 inline-block">
                                      {element?.data?.sku}
                                    </dd>
                                  </div>
                                </dl>
                                <div className="mt-6 space-y-2">
                                  <div className="text-sm underline text-indigo-600 hover:text-indigo-500 flex items-center">
                                    <Globe className="mr-2 h-4 w-4" />
                                    <a href={element?.endpoint} target='_blank' className='cursor-pointer'>
                                      {element?.endpoint}
</a>
                                  </div>
                                  <a href={element?.data?.repoUrl} target="_blank" rel="noopener noreferrer" className="text-sm text-gray-600 hover:text-gray-500 flex items-center">
                                    <FaGithub className="mr-2 h-4 w-4" />
                                    {element?.data?.repoUrl}
                                  </a>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              {showList && <GithubConnect searchTerm={searchTerm} setSearchTerm={setSearchTerm} setForward={setForward} repoSelected={repoSelected} repos={repositories} />}
              {indRepo && (
                <div className="mt-8">
                  <button onClick={goBack} className="flex items-center text-indigo-600 hover:text-indigo-800 mb-6">
                    <ArrowLeft className="mr-2" size={20} />
                    <span className="font-semibold">Go back</span>
                  </button>
                  <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                  {selectedType === "webservice" && (
                    <>
                        <div className="px-4 py-5 sm:px-6">
                          <h3 className="text-lg leading-6 mb-2 font-medium text-gray-900">Deploy your project</h3>
                          {details && (
                            <div className="space-y-2">
                              {details.outputs && (
                                <div>
                                  {details.outputs.map((output) => output.OutputKey === 'WebsiteURL' && (
                                    <div key={output.OutputKey} className="flex items-center">
                                      <a href={output.OutputValue} target="_blank" rel="noopener noreferrer" className="text-indigo-600 cursor-pointer underline mr-2">
                                        {output.OutputValue}
                                      </a>
                                      <button onClick={() => handleCopy(output.OutputValue)} className="text-indigo-600 hover:text-indigo-800">
                                        <FaCopy />
                                      </button>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          )}
                          <p className="mb-3 mt-8 max-w-2xl text-sm text-gray-500">
                            Configuration for {indRepo.name}
                          </p>
                          {deployedUrl && (
                            <p className="mt-2">
                              Your website is now available at: <a href={deployedUrl} target="_blank" rel="noopener noreferrer" className="font-medium text-blue-600 hover:text-blue-800 cursor-pointer underline">{deployedUrl}</a>
                            </p>
                          )}
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                          <form onSubmit={handleSubmitWebService}>
                            <dl className="sm:divide-y sm:divide-gray-200">
                              {[
                                {
                                  label: "Deployment Name",
                                  name: "webAppName",
                                  type: "text",
                                  validate: (value) => {
                                    const sanitized = value.replace(/[^a-zA-Z0-9-]/g, '');
                                    return sanitized.replace(/^[0-9]/, '');
                                  },
                                  transform: (value) => {
                                    return value.replace(/\s+/g, '-');
                                  }
                                },
                                { label: "Repository URL", name: "repoUrl", type: "text", disabled: true },
                                { label: "Branch", name: "branch", type: "text" },
                                { label: "Runtime", name: "runtime", type: "select", options: ["node", "python"] },
                                { label: "Startup Command", name: "startupCommand", type: "text" },
                                { label: "Location", name: "location", type: "select", options: ["east-us-1", "us-east-2", "us-west-1"] },
                                { label: "NodeJS version", name: "nodejsVersion", type: "select", options: ["18.x", "22.x", "20.x", "16.x", "14.x"] },
                              ].map((field, index) => (
                                <div key={index} className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-lg font-medium text-gray-800">{field.label}</dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <div className="flex items-center">
                                      {field.type === "select" ? (
                                        <select
                                          name={field.name}
                                          value={webServiceFormData[field.name]}
                                          onChange={handleWebServiceFormChange}
                                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        >
                                          {field.options.map((option, optionIndex) => (
                                            <option key={optionIndex} value={option}>{option}</option>
                                          ))}
                                        </select>
                                      ) : (
                                        <input
                                          type={field.type}
                                          name={field.name}
                                          value={webServiceFormData[field.name]}
                                          onChange={(e) => {
                                            let value = e.target.value;
                                            if (field.validate) {
                                              value = field.validate(value);
                                            }
                                            if (field.transform) {
                                              value = field.transform(value);
                                            }
                                            handleWebServiceFormChange({
                                              target: { name: field.name, value }
                                            });
                                            if (field.name === 'webAppName') {
                                              setNameAvailability(null);
                                            }
                                          }}
                                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                          required
                                          disabled={field.disabled}
                                        />
                                      )}
                                      {field.name === 'webAppName' && (
                                        <button
                                          type="button"
                                          onClick={handleCheckNameAvailability}
                                          className="ml-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                          Availability
                                        </button>
                                      )}
                                    </div>
                                    {field.name === 'webAppName' && nameAvailability && (
                                      <div className={`mt-2 font-medium text-sm ${nameAvailability === 'Name available!' ? 'text-green-500' : 'text-red-600'}`}>
                                        {nameAvailability}
                                      </div>
                                    )}
                                  </dd>
                                </div>
                              ))}
                            </dl>
                            <div className="mt-6 sm:px-6">
                              <h3 className="text-lg font-medium text-gray-900">Environment Variables</h3>
                              {webServiceFormData.envVars.map((envVar, index) => (
                                <div key={index} className="mt-2 flex items-center space-x-2">
                                  <input
                                    type="text"
                                    placeholder="Key"
                                    value={envVar.key}
                                    onChange={(e) => handleEnvVarChange(index, 'key', e.target.value)}
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  />
                                  <input
                                    type="text"
                                    placeholder="Value"
                                    value={envVar.value}
                                    onChange={(e) => handleEnvVarChange(index, 'value', e.target.value)}
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  />
                                  <button
                                    type="button"
                                    onClick={() => removeEnvVar(index)}
                                    className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                  >
                                    Remove
                                  </button>
                                </div>
                              ))}
                              <button
                                type="button"
                                onClick={addEnvVar}
                                className="mt-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                              >
                                Add Environment Variable
                              </button>
                            </div>

                            <PricingTable webServiceFormData={webServiceFormData} setWebServiceFormData={setWebServiceFormData} />
                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                              <button
                                type="submit"
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                disabled={deploying}
                              >
                                {deploying ? 'Deploying...' : `Deploy ${indRepo.name} as Web Service`}
                              </button>
                            </div>
                            {getResponse &&
                              <div className=' flex justify-center'>
                                <div className='rounded py-4 mt-4 mb-24 px-12 flex justify-center items-center bg-yellow-100'>
                                  <LoaderIcon className='text-yellow-600 w-5 animate-spin h-auto mr-1' />
                                  <p className="text-sm font-semibold text-yellow-600">
                                    Deploying... Please wait.
                                  </p>
                                </div>
                              </div>
                            }
                            {successful && details &&
                              <div className='flex justify-center'>
                                <div className='rounded flex items-center py-4 px-12 mt-4 mb-24 bg-green-100'>
                                  <CheckIcon className='text-green-600 w-5 h-auto mr-1' />
                                  <p className='text-green-600'>{details?.message}</p>
                                </div>
                              </div>
                            }
                          </form>
                        </div>
                    </>
                  )}
                    {selectedType === 'cron' && (
                      <form onSubmit={handleSubmitCron}>
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                          <dl className="sm:divide-y sm:divide-gray-200">
                            {[
                              { label: "Deployment Name", name: "webAppName", type: "text" },
                              { label: "Repository URL", name: "repoUrl", type: "text" },
                              { label: "Branch", name: "branch", type: "text" },
                              { label: "Runtime", name: "runtime", type: "select", options: ["node", "python"] },
                              { label: "Startup Command", name: "startupCommand", type: "text" },
                              { label: "Location", name: "location", type: "select", options: ["east-us-1", "us-east-2", "us-west-1"] },
                              { label: "NodeJS version", name: "nodejsVersion", type: "select", options: ["18.x", "22.x", "20.x", "16.x", "14.x"] },
                            ].map((field, index) => (
                              <div key={index} className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">{field.label}</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                  <input
                                    type={field.type}
                                    name={field.name}
                                    value={cronFormData[field.name]}
                                    onChange={handleCronFormChange}
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  />
                                </dd>
                              </div>
                            ))}
                          </dl>
                          <div className="mt-6 px-4">
                            <h4 className="text-lg font-medium text-gray-900 mb-4">Cron Jobs</h4>
                            {cronJobs.map((job, index) => (
                              <div key={index} className="flex px-4 items-center space-x-4 mb-4">
                                <input
                                  type="text"
                                  value={job.endpoint}
                                  onChange={(e) => handleCronJobChange(index, 'endpoint', e.target.value)}
                                  placeholder="Endpoint"
                                  className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                                <input
                                  type="text"
                                  value={job.schedule}
                                  onChange={(e) => handleCronJobChange(index, 'schedule', e.target.value)}
                                  placeholder="Cron Schedule"
                                  className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                                <button
                                  type="button"
                                  onClick={() => removeCronJob(index)}
                                  className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                >
                                  <FaTrash className="h-5 w-5" aria-hidden="true" />
                                </button>
                              </div>
                            ))}
                            <button
                              type="button"
                              onClick={addCronJob}
                              className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              <FaPlus className="mr-2" /> Add Cron Job
                            </button>
                          </div>
                        </div>
                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                          <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            disabled={deploying}
                          >
                            {deploying ? 'Deploying...' : 'Deploy Cron Job'}
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
          {activeSection === 'deploymentDetails' && renderDeploymentDetails()}
          {activeSection === 'integrations' && <Integrations user={user} />}
          {activeSection === 'billing' && <Billing userPlan={userPlan} setPaid={setPaid} initialOptions={initialOptions} />}
          {activeSection === 'teams' && <Teams />}
          {activeSection === 'user' && <AccountSettings user={user} />}
        </main>
      </div>
    </div>
  );
};

export default Dashboard;


const Billing = ({ setPaid, userPlan, initialOptions }) => {
  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-4">Billing</h2>
      <div className='grid grid-cols-2 bg-white shadow overflow-hidden sm:rounded-lg'>
        <div className="">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Billing Information</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Details about your current plan and usage.</p>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Current Plan</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                  <p className='mr-2'>{userPlan}</p>
                  {userPlan === "Free Tier" && (
                    <Link to="/pricing" className='text-sm bg-gray-200 cursor-pointer px-3 py-1 rounded hover:bg-gray-300'>
                      Upgrade
                    </Link>
                  )}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Next Billing Date</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {userPlan === "Free Tier" ? "N/A" : "1st of next month"}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Payment Method</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {userPlan === "Free Tier" ? "Not set" : "PayPal"}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

const Teams = () => {
  const [newEditor, setNewEditor] = useState({ name: '', email: '' });
  const [teamMembers, setTeamMembers] = useState([]);
  const [error, setError] = useState('');

  const fetchTeams = async () => {
    const user = auth.currentUser;
    if (user) {
      const q = query(collection(db, 'teams'), where('members', 'array-contains', user.uid));
      const querySnapshot = await getDocs(q);
      const members = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setTeamMembers(members);
    }
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  const handleInputChange = (e) => {
    setNewEditor({ ...newEditor, [e.target.name]: e.target.value });
  };

  const handleAddEditor = async (e) => {
    e.preventDefault();
    setError('');

    if (!newEditor.name || !newEditor.email) {
      setError('Please fill in all fields.');
      return;
    }

    try {
      const user = auth.currentUser;
      await addDoc(collection(db, 'teams'), {
        ownerId: user.uid,
        members: [user.uid],
        name: newEditor.name,
        email: newEditor.email,
        role: 'Editor'
      });

      setNewEditor({ name: '', email: '' });
      fetchTeams();
    } catch (error) {
      setError('Failed to add editor. Please try again.');
      console.error('Error adding editor:', error);
    }
  };

  const handleRemoveEditor = async (editorId) => {
    try {
      const user = auth.currentUser;
      const teamDoc = doc(db, 'teams', editorId);
      await updateDoc(teamDoc, {
        members: arrayRemove(user.uid)
      });
      fetchTeams();
    } catch (error) {
      setError('Failed to remove editor. Please try again.');
      console.error('Error removing editor:', error);
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-4">Teams</h2>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Your Team</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Manage your team members here.</p>
        </div>
        <div className="border-t border-gray-200">
          <ul className="divide-y divide-gray-200">
            {teamMembers.map((member) => (
              <li key={member.id} className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300"></div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">{member.name}</div>
                      <div className="text-sm text-gray-500">{member.email}</div>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="text-sm text-gray-500 mr-4">{member.role}</div>
                    {member.ownerId !== auth.currentUser.uid && (
                      <button
                        onClick={() => handleRemoveEditor(member.id)}
                        className="text-red-600 hover:text-red-800"
                      >
                        <Trash2 size={20} />
                      </button>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Add New Editor</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Invite a new team member as an editor.</p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <form onSubmit={handleAddEditor} className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                name="name"
                id="name"
                value={newEditor.name}
                onChange={handleInputChange}
                className="mt-1 focus:ring-indigo-500 p-2 border border-indigo-300 outline-none focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                value={newEditor.email}
                onChange={handleInputChange}
                className="mt-1 focus:ring-indigo-500 p-2 border border-indigo-300 outline-none focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            {error && <p className="text-red-500 text-sm">{error}</p>}
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Add Editor
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

const Integrations = ({user}) => {
  const [loading, setLoading] = useState(false);
  const [roleArn, setRoleArn] = useState('');
  const [showDeployForm, setShowDeployForm] = useState(false);
  const [cfnUrl, setCfnUrl] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (user) {
      fetchRoleArn();
    }
  }, [user]);


  const [identifier, setidentifier] = useState("")
  const fetchRoleArn = async () => {
    setLoading(true);
    try {
      const deploymentsRef = collection(db, "roles");
      const q = query(deploymentsRef, where("uid", "==", user.uid));
      const querySnapshot = await getDocs(q);
      const fetchedDeployments = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      console.log(fetchedDeployments)
      setRoleArn(fetchedDeployments[0]?.roleArn)
      setidentifier(fetchedDeployments[0]?.id)
      const deploymentWithRole = fetchedDeployments.find(dep => dep.roleArn);
      if (deploymentWithRole) {
        setShowDeployForm(true);
      }
    } catch (error) {
      console.error("Error fetching deployments:", error);
      setMessage(`Error fetching deployments: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const [id, setId] = useState("")

  const requestIAMRole = async () => {
    if (!user) {
      setMessage('Please sign in to request AWS permissions.');
      return;
    }
    setLoading(true);
    try {
      const result = await mockRequestPermission(id);
      setCfnUrl(result.cfnUrl);
      window.open(result.cfnUrl, '_blank');
      setMessage('IAM role request initiated. Please complete the stack creation in the new tab.');
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const checkIAMRoleStatus = async () => {
    if (!user) {
      setMessage('Please sign in to check AWS permissions.');
      return;
    }
    setLoading(true);
    try {
      const result = await mockCheckPermission(id);
      if (result.permissionGranted) {
        console.log(result)
        const generatedRoleArn = `arn:aws:iam::${id}:role/CCRole`;
        setRoleArn(generatedRoleArn);
        await setDoc(doc(db, 'roles', id), {
          uid: user.uid, 
          roleArn: generatedRoleArn
        }, { merge: true });
        setShowDeployForm(true);
        setMessage('AWS permissions granted successfully!');
      } else {
        setMessage('Permissions not yet granted. Please complete the AWS CloudFormation stack creation.');
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const onDeploySubmit = async (event) => {
    event.preventDefault();
    if (!user) {
      setMessage('Please sign in to deploy.');
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData(event.target);
      const values = Object.fromEntries(formData.entries());

      const response = await fetch('http://localhost:5000/deploy-aws-integration', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...values,
          userRoleArn: roleArn,
          id: identifier,
          uid: user.uid,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        setMessage('Deployment successful!');
      } else {
        throw new Error(data.error || 'Deployment failed');
      }
    } catch (error) {
      setMessage(`Deployment failed: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-4">AWS Deployment</h2>
      {message && (
        <div className="mb-4 p-4 bg-blue-100 text-blue-700 rounded-md">
          {message}
        </div>
      )}
      {!roleArn &&
        <div className=''>
          <p>Enter your AWS account ID</p>
          <input
            value={id}
            onChange={(e) => setId(e.target.value)}
            className='rounded p-2 mb-6 border border-blue-600 outline-none mt-1'
          />
        </div>
      }
      {!user && (
        <div className="mb-4 p-4 bg-yellow-100 text-yellow-700 rounded-md">
          Please sign in to manage AWS permissions and deploy.
        </div>
      )}
      {user && !roleArn && (
        <div className="mb-4">
          <div className="mb-4 p-4 bg-yellow-100 text-yellow-700 rounded-md">
            To deploy your application, we need permission to create resources in your AWS account. This process will create an IAM role with limited permissions necessary for deployment.
          </div>
          <button
            onClick={requestIAMRole}
            disabled={loading}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
          >
            {loading ? 'Processing...' : 'Request AWS Permissions'}
          </button>
          {cfnUrl && (
            <button
              onClick={checkIAMRoleStatus}
              disabled={loading}
              className="ml-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 disabled:bg-green-300"
            >
              {loading ? 'Checking...' : 'Check Permissions Status'}
            </button>
          )}
        </div>
      )}
      {user && showDeployForm && (
        <>
          <div className="mb-4 p-4 bg-green-100 text-green-700 rounded-md">
            Your AWS account is now linked with role ARN: {roleArn}
          </div>
          <form onSubmit={onDeploySubmit} className="space-y-4">
            <div>
              <label htmlFor="webAppName" className="block text-sm font-medium text-gray-700">Web App Name</label>
              <input type="text" id="webAppName" name="webAppName" required className="mt-1 outline-none p-1 border border-indigo-600 rounded w-full" />
            </div>
            <div>
              <label htmlFor="repoUrl" className="block text-sm font-medium text-gray-700">GitHub Repository URL</label>
              <input type="text" id="repoUrl" name="repoUrl" required className="mt-1 outline-none p-1 border border-indigo-600 rounded w-full" />
            </div>
            <div>
              <label htmlFor="branch" className="block text-sm font-medium text-gray-700">Branch</label>
              <input type="text" id="branch" name="branch" required className="mt-1 outline-none p-1 border border-indigo-600 rounded w-full" />
            </div>
            <div>
              <label htmlFor="startupCommand" className="block text-sm font-medium text-gray-700">Startup Command</label>
              <input type="text" id="startupCommand" name="startupCommand" required className="mt-1 outline-none p-1 border border-indigo-600 rounded w-full" />
            </div>
            <div>
              <label htmlFor="nodejsVersion" className="block text-sm font-medium text-gray-700">Node.js Version</label>
              <input type="text" id="nodejsVersion" name="nodejsVersion" required className="mt-1 outline-none p-1 border border-indigo-600 rounded w-full" />
            </div>
            <button
              type="submit"
              disabled={loading}
              className="w-full bg-indigo-500 text-white px-4 py-2 rounded hover:bg-indigo-600 disabled:bg-indigo-300"
            >
              {loading ? 'Deploying...' : 'Deploy'}
            </button>
          </form>
        </>
      )}
    </div>
  );
};


const AccountSettings = ({ user }) => {
  const [editMode, setEditMode] = useState({
    email: false,
    fullName: false,
    githubUsername: false
  });
  const [userData, setUserData] = useState({
    email: user?.email,
    fullName: user?.displayName,
    githubUsername: ''
  });

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          setUserData(prevState => ({
            ...prevState,
            githubUsername: data.githubUsername || ''
          }));
        }
      }
    };
    fetchUserData();
  }, [user]);

  const handleEdit = (field) => {
    setEditMode({ ...editMode, [field]: true });
  };

  const handleSave = async (field) => {
    setEditMode({ ...editMode, [field]: false });
    const userDocRef = doc(db, 'users', user.uid);

    try {
      if (field === 'fullName') {
        await updateProfile(auth.currentUser, { displayName: userData.fullName });
      }
      await updateDoc(userDocRef, { [field]: userData[field] });
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  };

  const handleChange = (e, field) => {
    setUserData({ ...userData, [field]: e.target.value });
  };

  const renderField = (label, field, value) => (
    <li className="px-4 py-4 sm:px-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className="text-sm font-medium text-gray-900">{label}</div>
        </div>
        <div className="flex items-center">
          {editMode[field] ? (
            <>
              <input
                type="text"
                value={value}
                onChange={(e) => handleChange(e, field)}
                className="text-sm text-gray-500 mr-2 border rounded px-2 py-1"
              />
              <button
                onClick={() => handleSave(field)}
                className="text-green-600 hover:text-green-800"
              >
                Save
              </button>
            </>
          ) : (
            <>
              <div className="text-sm text-gray-500 mr-2">{value}</div>
              <button
                onClick={() => handleEdit(field)}
                className="text-blue-600 hover:text-blue-800"
              >
                <Pen size={16} />
              </button>
            </>
          )}
        </div>
      </div>
    </li>
  );

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-4">Account Settings</h2>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Profile</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Manage your account information here.</p>
        </div>
        <div className="border-t border-gray-200">
          <ul className="divide-y divide-gray-200">
            {renderField('Contact Email', 'email', userData.email)}
            {renderField('Full Name', 'fullName', userData.fullName)}
            {renderField('GitHub Username', 'githubUsername', userData.githubUsername)}
          </ul>
        </div>
      </div>
    </div>
  );
};




