import React, { useState, useEffect } from 'react';
import { initializeApp } from "firebase/app";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { AlertCircle } from 'lucide-react';
import { getAuth, onAuthStateChanged } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBPxueucxQtV0Zbxe8BtlT6GoSR753TNd4",
    authDomain: "gammafy-638dc.firebaseapp.com",
    projectId: "gammafy-638dc",
    storageBucket: "gammafy-638dc.appspot.com",
    messagingSenderId: "849864306670",
    appId: "1:849864306670:web:506911cfb604f41628a8ff",
    measurementId: "G-74ZH9LRDP3"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const Checkout = ({ setPaid, selectedPlan }) => {
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    const [currency, setCurrency] = useState(options.currency);
    const [user, setUser] = useState(null);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
            }
        });
    }, []);

    const onCurrencyChange = ({ target: { value } }) => {
        setCurrency(value);
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: value,
            },
        });
    }

    const onCreateOrder = (data, actions) => {
        if (!selectedPlan) {
            alert("Please select a plan before checkout.");
            return;
        }
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: selectedPlan.price.toString(),
                    },
                },
            ],
        });
    }

    const onApproveOrder = (data, actions) => {
        return actions.order.capture().then(async (details) => {
            alert("You can now access all the services");
            setPaid(true);

            if (user && selectedPlan) {
                const userRef = doc(db, 'paying_users', user.uid);
                await setDoc(userRef, {
                    paid: true,
                    plan: selectedPlan.name,
                    price: selectedPlan.price
                }, { merge: true });
            } else {
                console.error("User is not authenticated or no plan selected");
            }
        });
    };

    return (
        <div className="py-20 2xl:w-1/3 flex justify-center">
            <div className='w-5/6 sm:w-full 2xl:w-5/6'>
                {isPending ? <p>Loading...</p> : (
                    <>
                        <select value={currency} onChange={onCurrencyChange}>
                            <option value="USD">💵 USD</option>
                            <option value="EUR">💶 Euro</option>
                        </select>
                        {selectedPlan ? (
                            <PayPalButtons
                                style={{ layout: "vertical" }}
                                createOrder={(data, actions) => onCreateOrder(data, actions)}
                                onApprove={(data, actions) => onApproveOrder(data, actions)}
                            />
                        ) : (
                                <div className="mt-4 p-4 border border-yellow-400 bg-yellow-50 rounded-md flex items-center">
                                    <AlertCircle className="text-yellow-400 mr-3" size={24} />
                                    <div>
                                        <p className="font-semibold text-yellow-700">No plan selected</p>
                                        <p className="text-yellow-600">Please choose a plan from the options above to proceed with checkout.</p>
                                    </div>
                                </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default Checkout;